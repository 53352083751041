<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Flock Data Entry</h2>
					</div>
				</div>
			</div>
		</div>
		<b-card>
			<b-row>
				<b-col md="5">
					<b-form-group>
						<template v-slot:label> Select Shed QR Code<span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<!-- <validation-provider
                #default="{ errors }"
                name=" Select Shed QR Code"
                rules="required"
              > -->
							<v-select
								:value="$route.params.shedId"
								:options="shedList"
								clearable:false
								label="shed_name"
								:searchable="true"
								:reduce="(e) => `${e.id}`"
								@input="getFlockListForSelectShed"
							/>
							<!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
						</div>
					</b-form-group>
				</b-col>

				<b-col md="5">
					<b-form-group>
						<template v-slot:label> Flock Id <span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<!-- <validation-provider
                #default="{ errors }"
                name="flock_id"
                rules="required"
              > -->
							<v-select
								:value="$route.params.flockId"
								:options="flockDataList"
								label="label"
								:clearable="false"
								:searchable="true"
								:reduce="(e) => `${e.flock_id}`"
								v-on:input="getSelectedItem"
							/>
							<!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
						</div>
					</b-form-group>
				</b-col>
				<!-- submit and reset -->
				<!-- <b-col md="2" class="d-flex submit-button">
          <b-button type="submit" variant="primary" value="Submit" class="mr-1">
            Submit
          </b-button>
        </b-col> -->
			</b-row>
		</b-card>
		<!-- tabs -->

		<!-- Table Container Card -->
		<b-card no-body class="mb-2" v-if="$route.params.flockId">
			<!-- <b-tabs vertical nav-wrapper-class=" col-3  mt-5 pt-3 nav-vertical ">
        <div class="col-9">
          <b-tab active nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/feed-supplement.svg')"
                  style="width: 25px"
                />
                <span>Feed </span>
              </div>
            </template>
            <FeedDataEntry />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/bird-sale.svg')"
                  style="margin-right: 7px; width: 20px"
                />
                <span>Bird Sale</span>
              </div>
            </template>
            <BirdSaleList />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/weight.svg')"
                  style="margin-right: 7px; width: 20px"
                />
                <span>Weight</span>
              </div>
            </template>
            <div class="col-12">
              <WeightList />
            </div>
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/mortality.svg')"
                  style="margin-right: 7px; width: 25px"
                />
                <span>Mortality</span>
              </div>
            </template>
            <Mortality />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/medicine.svg')"
                  style="margin-right: 7px; width: 20px"
                />
                <span>Medicine</span>
              </div>
            </template>
            <Mortality />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/treatment.svg')"
                  style="margin-right: 7px; width: 25px"
                />
                <span>Treatment </span>
              </div>
            </template>
            <TreatmentList />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/vaccine.svg')"
                  style="margin-right: 7px; width: 20px"
                />
                <span>Vaccination</span>
              </div>
            </template>
            <VaccineList />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/egg-weight.svg')"
                  style="margin-right: 7px; width: 25px"
                />
                <span>Egg Weight </span>
              </div>
            </template>
            <EggWeight />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/egg-production.svg')"
                  style="margin-right: 7px; width: 25px"
                />
                <span>Egg Production </span>
              </div>
            </template>
            <EggProduction />
          </b-tab>
          <b-tab nav-item>
            <template #title>
              <div>
                <b-img
                  :src="require('@/assets/images/svg/water-consumption.svg')"
                  style="margin-right: 7px; width: 20px"
                />
                <span>Water Consumption</span>
              </div>
            </template>
            <WaterConsumption />
          </b-tab>
        </div>
      </b-tabs> -->

			<b-tabs lazy class="m-2">
				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/feed-supplement.svg')" style="width: 25px" />
							<span>Feed </span>
						</div>
					</template>
					<FeedDataEntry />
				</b-tab>

				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/weight.svg')" style="margin-right: 7px; width: 20px" />
							<span>Weight</span>
						</div>
					</template>
					<WeightList />
				</b-tab>

				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/medicine.svg')" style="margin-right: 7px; width: 20px" />
							<span>Medicine</span>
						</div>
					</template>
					<Medicine />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/treatment.svg')" style="margin-right: 7px; width: 25px" />
							<span>Treatment </span>
						</div>
					</template>
					<TreatmentList />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/vaccine.svg')" style="margin-right: 7px; width: 20px" />
							<span>Vaccination</span>
						</div>
					</template>
					<VaccineList />
				</b-tab>

				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/egg-production.svg')" style="margin-right: 7px; width: 25px" />
							<span>Egg Production </span>
						</div>
					</template>
					<EggProduction />
				</b-tab>

				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/egg-weight.svg')" style="margin-right: 7px; width: 25px" />
							<span>Egg Weight </span>
						</div>
					</template>
					<EggWeight />
				</b-tab>

				<b-tab>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/water-consumption.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Water Consumption</span>
						</div>
					</template>
					<WaterConsumption />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/mortality.svg')" style="margin-right: 7px; width: 25px" />
							<span>Mortality / Morbidity</span>
						</div>
					</template>
					<Mortality />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/bird-sale.svg')" style="margin-right: 7px; width: 20px" />
							<span>Bird Sale</span>
						</div>
					</template>
					<BirdSaleList />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/dashboard/chick_placement.png')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Chick Placement</span>
						</div>
					</template>
					<ChickPlacement />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/dashboard/chick_placement.png')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Chick Transfer</span>
						</div>
					</template>
					<ChickTransfer />
				</b-tab>
			</b-tabs>
		</b-card>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">Data Entry List Empty</h4>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import EggProduction from "./EggProduction.vue";
import FeedDataEntry from "./FeedDataEntry.vue";
import Medicine from "./Medicine.vue";
import Mortality from "./Mortality.vue";
import VaccineList from "./VaccineList.vue";
import WeightList from "./WeightList.vue";
import WaterConsumption from "./WaterConsumption.vue";
import ChickPlacement from "./ChickPlacement.vue";
import ChickTransfer from "./ChickTransfer.vue";
import BirdSaleList from "./BirdSaleList.vue";
import TreatmentList from "./TreatmentList.vue";
import EggWeight from "./EggWeight.vue";
import { BImg } from "bootstrap-vue";

export default {
	components: {
		vSelect,
		EggProduction,
		FeedDataEntry,
		Medicine,
		Mortality,
		VaccineList,
		WeightList,
		WaterConsumption,
		ChickPlacement,
		BirdSaleList,
		TreatmentList,
		EggWeight,
		ChickTransfer,
		BImg,
	},
	data() {
		return {
			selectShed: null,
			selectFlockList: null,
			shedList: [],
			flockDataList: [],
			flockId: null,
			farmId: null,
			shedId: null,
		};
	},
	created() {
		this.getShedList();
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		if (this.shedId != null) {
			this.getFlockListForSelectShed(this.shedId);
		}
		this.flockId = this.$route.params.flockId;
	},
	methods: {
		getShedList() {
			const farmId = this.$route.params.farmId;
			axiosIns
				.get(`web/farm/${farmId}/sheds`)
				.then((response) => {
					this.shedList = response.data.shed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getFlockListForSelectShed(shedId) {
			const farmId = this.$route.params.farmId;
			this.shedId = shedId;
			axiosIns
				.get(`web/farm/${farmId}/shed/${shedId}/flock-list`)
				.then((response) => {
					// this.flockDataList = response.data.flock_list;
					this.flockDataList = response.data.flock_list.map((flock) => ({
						...flock,
						label: `${flock.flock_id} (${flock.flock_name})`,
					}));

					if (!this.flockId) {
						this.$router.push({
							path: `/apps/manage-farm/${farmId}/shed/${shedId}`,
						});
					}
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSelectedItem(event) {
			let flockId = event;
			let farmId = this.farmId;
			let shedId = this.shedId;
			this.$router.push({
				path: `/apps/manage-farm/${farmId}/shed/${shedId}/data-entry/${flockId}`,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.nav-link {
	padding: 1rem 1.2rem !important;
}
.per-page-selector {
	width: 90px;
}

.submit-button {
	align-items: center;
}
.tabs {
	flex-wrap: nowrap !important;
}
.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}

	.v-select .dropdown-toggle .clear {
		display: none;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.nav-link {
	padding: 1rem 1.2rem !important;
}
</style>
