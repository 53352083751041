<template>
	<div class="">
		<b-row class="mb-2 d-flex justify-content-end">
			<!-- Search -->
			<b-col cols="12" md="5" class="mt-2 d-flex justify-content-end align-items-center">
				<b-button
					@click="$router.push(`/apps/manage-farm/${farmId}/shed/${shedId}/data-entry/${flockId}/chick-placement/add`)"
					variant="primary mr-1 ml-2"
				>
					Add
				</b-button>
			</b-col>
		</b-row>
		<div class="p-2" v-if="chickPlacement && chickPlacement.total > 0">
			<b-table id="chickPlacement" :items="chickPlacement.data" responsive :fields="fields" class="position-relative">
				<template #cell(index)="data">
					<div class="ml-1">
						{{ chickPlacement.from + data.index }}
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="chickPlacement.current_page"
					:total-rows="chickPlacement.total"
					:per-page="chickPlacement.per_page"
					align="right"
					@change="getChickPlacementList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">Chick Placement Data Entry List Empty</h4>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			searchQuery: null,
			page: null,
			fields: [
				"index",
				{
					key: "created_at",
					label: "Chick Placed date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				"flock_id",
				"hatch_no",
				"chick_placed",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			chickPlacement: {},
		};
	},
	created: function () {
		this.getChickPlacementList();
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
	},
	methods: {
		moment() {
			return moment();
		},

		getChickPlacementList(pageNo = 1) {
			const farm_id = this.$route.params.farmId;
			const flockId = this.$route.params.flockId;

			let url = `web/chick-placement-list/${flockId}`;

			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.chickPlacement = response.data.chick_placement;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-water-entry`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getChickPlacementList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getChickPlacementList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getChickPlacementList();
		},
	},
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #296db4;
	color: #fff;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
