<template>
	<div class="">
		<b-row class="mb-2 d-flex justify-content-end">
			<!-- Search -->
			<b-col cols="12" md="5" class="mt-2 d-flex justify-content-end align-items-center">
				<b-button
					@click="$router.push(`/apps/manage-farm/${farmId}/shed/${shedId}/data-entry/${flockId}/chick-transfer/add`)"
					variant="primary mr-1 ml-2"
				>
					Add
				</b-button>
			</b-col>
		</b-row>
		<div class="p-2" v-if="chickTransfer && chickTransfer.total > 0">
			<b-table id="chickTransfer" :items="chickTransfer.data" responsive :fields="fields" class="position-relative">
				<template #cell(index)="data">
					<div class="ml-1">
						{{ chickTransfer.from + data.index }}
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="chickTransfer.current_page"
					:total-rows="chickTransfer.total"
					:per-page="chickTransfer.per_page"
					align="right"
					@change="getChickTransferList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">Chick Transfer Data Entry List Empty</h4>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			searchQuery: null,
			page: null,
			fields: [
				"index",
				// {
				// 	key: "created_at",
				// 	label: "Chick Placed date",
				// 	formatter: (value) => {
				// 		return moment(value).format("MMMM Do YYYY");
				// 	},
				// },
				"old_flock_id",
				"new_flock_id",
				"birds_moved",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			chickTransfer: {},
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getChickTransferList();
	},
	methods: {
		moment() {
			return moment();
		},

		getChickTransferList(pageNo = 1) {
			const farm_id = this.$route.params.farmId;
			const flockId = this.$route.params.flockId;

			let url = `web/chick-transfer-list/${flockId}`;

			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.chickTransfer = response.data.chick_transfer_list;
				})
				.catch((error) => {
					this.chickTransfer = null;
					this.$refs.setErrors(error.response.data.error);
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getChickTransferList();
		},
	},
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #296db4;
	color: #fff;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
